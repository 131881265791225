<template>
<div id="testeeQuery">
  <div class="testee-centreOrAdd clearfix">
    <span class="testee-centre fl">研究中心：</span>
    <el-select class="fl" v-model="centre" placeholder="请选择" size="mini" v-show="centreOptions.length>=1" @change="centerChange">
      <el-option v-for="item in centreOptions" :key="item.centerKey" :label="item.centerName" :value="item.centerKey"></el-option>
    </el-select>
  </div>
  <div class="m-table-wrap">
    <template>
      <el-table :data="tableData" border style="width: 100%" :header-cell-class-name="tableHeaderCn" :row-class-name="tableRowCn">
        <el-table-column prop="number" label="受试者编号" width="180">
        </el-table-column>
        <el-table-column prop="name" label="访视名称" width="180">
        </el-table-column>
        <el-table-column prop="fromName" label="表单名称">
        </el-table-column>
        <el-table-column prop="ddName" label="问题">
        </el-table-column>
        <el-table-column prop="ddValue" width="180" label="值">
        </el-table-column>
        <el-table-column prop="content" label="质疑内容">
        </el-table-column>
        <el-table-column prop="address" label="质疑状态">
          <template slot-scope="scope">
            <p class="q-t-wrap"> <i class="question-icon-wrap" :class="['icon'+ (scope.row.dataStatus ?scope.row.dataStatus:0)]"></i><span>{{formatterQuest(scope.row.dataStatus)}}</span>
            </p>
          </template>
        </el-table-column>
        <el-table-column width="180" label="编辑">
          <template slot-scope="scope">
            <p class="canEdit">
              <i @click="onJumpEdit(scope.row)" class="el-icon-view"> 查看</i>
              <span> <i @click="onQuestion(scope.row)" class="el-icon-edit"> 回复</i></span>
            </p>
          </template>
        </el-table-column>
      </el-table>
    </template>
  </div>
  <el-dialog :title="questionTitle" :visible.sync="questionDialogShow" :close-on-click-modal='true'>
    <div class="dialog-footer" v-if="questionDialogShow">
      <QuestionToast :qaIndex='qaIndex' :btnSendLoading='btnSendLoading' :btnCloseLoading='btnCloseLoading' ref="qtost" :leftQAList='leftQAList' @onSendquestion='onSendquestion' @addNewQA='addNewQA' @onCloseQuestion='onCloseQuestion' @setIndex='setIndex' :tloading='tloading'></QuestionToast>
    </div>
  </el-dialog>
</div>
</template>

<script>
import {
  getCenter,
  getAllQuestionBySite
} from "@/api/testee";
import {
  stopQuestionList,
  addQuestionList,
  addQuestion,
  getAllQuestionList
} from "@/api/formEntry";
import {
  Loading,
  Switch
} from 'element-ui';
import QuestionToast from '../components/QuestionToast'
let loadingInstance = null;
import {mapState } from 'vuex'
export default {
  name: 'TesteeQuery',
  components: {
    QuestionToast
  },
  data() {
    return {
      msg: '质疑管理',
      questionTitle: '',
      centreOptions: [],
      researchCode: this.$route.query.researchCode,
      centre: '',
      centerName: '',
      tableData: [],
      questionDialogShow: false,
      btnSendLoading: false,
      btnCloseLoading: false,
      tloading: true,
      leftQAList: [{
        list: []
      }],
      qaIndex: 0,
      addQAItem: {},
    }
  },
  computed: {
    ... mapState(['versionFineshed'])
  },
  watch: {
    versionFineshed(n,o){
      if (n != o) {
        this.initCenterList()
      }
    },
  },
  created() {
   this.initCenterList()
  },
  methods: {
    initCenterList(){
      loadingInstance = Loading.service({
        fullscreen: true,
        text: 'Loading',
      });
      this.getCenterList();
    },
    //获取中心列表
    getCenterList() {
      const postData = {
        researchCode: this.$route.query.researchCode,
        versionCode: JSON.parse(localStorage.getItem('versionCode'))
      };
      getCenter(postData)
        .then(res => {
          this.centreOptions = res.data.dataList;
          if (this.centreOptions.length === 0) {
            loadingInstance.close();
            return
          }
          this.onGetAllQuestionBySite(this.centreOptions[0].centerKey)
          this.centre = this.centreOptions[0].centerKey
          this.centerName = this.centreOptions[0].centerName
          loadingInstance.close();
        })
        .catch(error => {
          loadingInstance.close();
        });
    },
    onGetAllQuestionBySite(centerKey) {
      getAllQuestionBySite({
        centerCode: centerKey,
        creatRole: this.$authorContainer.roleId || '临床研究监查员(CRC)'
      }).then((res) => {
        if (res.code == 200) {
          this.tableData = res.data
        }
        loadingInstance.close();
        console.log(res)

      }).catch((error) => {

      })
    },
    centerChange(centerKey) {
      this.onGetAllQuestionBySite(centerKey)
    },
    onJumpEdit(v) {
      this.$router.push({
        name: 'FormEntry',
        query: {
          registryEntityCode: this.$route.query.researchCode,
          researchCode: this.$route.query.researchCode,
          researchName: this.$route.query.researchName,
          centerName: this.centerName,
          outpatientNumber: v.number,
          centerKey: this.centre,
          parentCode: v.parentCode,
          patientId: v.patientId,
          exampleId: v.exampleId,
          versionCode: JSON.parse(localStorage.getItem('versionCode')),
          testeeName: v.fromName
        }
      });
    },
    tableHeaderCn() {
        return 'testee-table_header';
    },
    tableRowCn() {
        return "testee-table_row";
    },
    onQuestion(item) {
      this.addQAItem = item
      this.questionDialogShow = true
      this.questionTitle = `质疑：${item.ddName}`
      this.leftQAList = []
      getAllQuestionList({
        exampleId: item.exampleId02,
        ddCode: item.ddCode,
        roleId:localStorage.getItem('user'),
      }).then((res) => {
        if (res.code === 200) {
          this.leftQAList = res.data
          this.leftQAList .map((e,i)=>{
            if (e.code == item.code) {
              this.qaIndex = i
            }
          })
          this.tloading = false
          if (this.leftQAList.length) {
            this.setSend(false)
          }
          this.$refs.qtost.onScrollBottom()
        } else {
          this.$message({
            message: res.msg,
            type: 'error'
          })
        }
      }).catch((error) => {
        this.tloading = false
        console.log(error);
      })
    },
    onSendquestion({
      item,
      cb,
      i,
      user
    }) {
      this.questionDialogShow = true
      this.btnSendLoading = true
      addQuestion({
        valueId: item.code,
        content: item.content,
        b: item.b,
        solve: user,
        creatRole: this.$authorContainer.roleId || '临床研究监查员(CRA)'
      }).then((res) => {
        this.leftQAList = res.data
        this.setSend(false)
        this.btnSendLoading = false
        cb()
        this.onGetAllQuestionBySite(this.centre)
      }).catch((err) => {
        console.log(err);
        this.btnSendLoading = false
      })
    },
    addNewQA({
      addData,
      cb,
      user
    }) {
      let params = {
        ddCode: this.addQAItem['ddCode'],
        exampleId: this.addQAItem['exampleId02'],
        registryEntityCode: this.addQAItem['registryEntityCode'],
        content: addData.content,
        solve: user,
        creatRole: localStorage.getItem('user') || '临床研究监查员(CRA)'
      }
      addQuestionList(params).then((res) => {
        if (res.code === 200) {
          this.leftQAList = res.data
          this.setSend(false)
          cb()
          this.onGetAllQuestionBySite(this.centre)
        } else {}
      }).catch((err) => {
        console.log(err);
      })
    },
    onCloseQuestion(item) {
      let params = {
        id: item.id
      }
      this.btnCloseLoading = true
      stopQuestionList({
          id: item.code
        })
        .then((res) => {
          this.btnCloseLoading = false
          this.leftQAList = res.data
          this.setSend(false)
          console.log(res);
          if (res.code === 200) {
            this.$message({
              message: res.msg,
              type: 'success'
            })
            this.onGetAllQuestionBySite(this.centre)
          } else {
            this.$message({
              message: res.msg,
              type: 'error'
            })
          }
        })
        .catch((err) => {})

    },
    setSend(bool) {
      this.$set(this.leftQAList[this.qaIndex], 'canSend', bool)
    },
    setIndex(i) {
      this.$set(this, 'qaIndex', i)
    },
    formatterQuest(v) {
      let test = ''

      switch (parseInt(v)) {
        case 11:
          test = '新增质疑'
          break;
        case 12:
          test = '等待我回复'
          break;
        case 13:
          test = '等待他人回复'
          break;
        case 14:
          test = '已关闭质疑'
          break;
        default:
          break;
      }
      return test
    }
  },
   beforeRouteLeave(to,from,next){
            loadingInstance && loadingInstance.close();
             next()
        }
}
</script>

<style lang="scss" >
.testee-table_header {
  height: 48px;
  background-color: #eaecef !important;
  border-right: none !important;

  .cell {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    word-break: break-all;
    color: #000 !important;
  }
}
</style>
<style lang="scss" scoped>

#testeeQuery {
  position: absolute;
  top: 102px;
  bottom: 16px;
  left: 16px;
  right: 16px;
  background: #fff;
  padding: 1%;
  min-width: 720px;

  .m-table-wrap {
    padding-top: 12px;
  }

  .q-t-wrap {
    display: flex;
    align-items: center;

    span {
      padding-left: 6px;
    }
  }

  .canEdit {
    span {
      margin-left: 18px;
    }
  }
}
</style>
