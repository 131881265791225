<template>
<div class="wrap-zy">
  <div class="left-wrap">
    <div class="lt-zy" @click="addNewZY">
      <span>质疑</span><i class="el-icon-document-add"></i>
    </div>
    <div class="l-q-list-wrap" :class="{'qa-active':index == qaIndex}" v-for="(item,index) in leftQAList" :key="item.id" @click="onSelectQA(item,index)">
      <i class="question-icon-wrap" :class="['icon'+ (item.dataStatus ?item.dataStatus:0)]"></i>
      <p>{{item.content}}</p>
    </div>
  </div>
  <div class="right-wrap">
    <div class="submit-q-wrap">
      <el-input type="textarea" :rows="4" :placeholder="placeHolderZY" v-model="message">
      </el-input>
      <!-- v-show="leftQAList[this.qaIndex].dataStatus" -->
      <div class="bottom-send-wrap">
        <p class="closebtn" :loading='btnSendLoading' type="success" size="mini" @click="onSendquestion">{{sendBtn}}</p>
        <p v-authorizeRole="{'itemCode':'question_close',menuContainer:$authorContainer}" v-if="closeBtn&& leftQAList.length" class="sendbtn" :loading='btnCloseLoading' type="primary" plain size="mini" @click="onCloseQuestion">关闭</p>
      </div>
    </div>
    <div class="list-wrap" v-if="leftQAList.length && addnew ">
      <div class="msg-row" v-for="item in leftQAList[qaIndex].list" :key="item.id" ref="scrollItem">
        <div class="list-top-msg">
          <p><i class="el-icon-s-custom"></i><span>{{item.creatName}}</span><span v-if="item.creatRole">({{item.creatRole}})</span></p>
          <p><span>{{item.creatTime}}</span></p>
        </div>
        <div class="list-b-msg">
          <span>{{item.content}}</span>
        </div>
      </div>

    </div>
  </div>
</div>
</template>

<script>
export default {
  props: {
    leftQAList: {
      type: Array,
      default: () => {
        return []
      }
    },
    tloading: {
      type: Boolean,
      default: () => {
        return true
      }
    },
    btnCloseLoading: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    btnSendLoading: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    qaIndex: {
      type: Number
    }
  },
  watch: {
    'leftQAList.length': function (n, o) {
      console.log(n, o, this.qaIndex);
      if (o !== n) {
        this.$emit('setIndex', this.qaIndex)
      }
    }
  },
  data() {
    return {
      closeBtn: true,
      visible: false,
      isCRA: localStorage.getItem('user') !== 'CRA',
      questionMessage: '',
      message: '',
      value: '',
      addnew: true,
      // placeHolderZY:'回复质疑：',
      // sendBtn:'提交'
    }
  },
  computed: {
    options: function () {
      let user = localStorage.getItem('user')
      return this.$CR[user]
    },
    sendBtn: function () {
      return (this.leftQAList.length && this.addnew) ? '更新' : '提交'
    },
    placeHolderZY: function () {
      return (this.leftQAList.length && this.addnew) ? '回复质疑：' : '新增质疑：'
    },
    isSend: function () {
      return true;
    }
  },

  methods: {
    /**
     * onScrollBottom 滚动问答区域置最底部以便每次都是最新的答案
     */
    onScrollBottom(e) {
      this.addnew = true
      this.message = ''
      this.$nextTick(() => {
        let timer = null
        if (!this.$refs.scrollWrap) {
          return
        }
        const maxH = this.$refs.scrollWrap.scrollHeight
        const clientH = this.$refs.scrollWrap.clientHeight
        let dy = 0
        let stap = 0
        if (maxH <= clientH) {
          dy = 0
        } else {
          dy = this.$refs.scrollItem[this.$refs.scrollItem.length - 2].clientHeight
        }
        timer = setInterval(() => {
          if (stap >= dy) {
            stap = dy
            clearInterval(timer)
          } else {
            stap += 14
          }
          this.$refs.scrollWrap.scrollTop += 14
        }, dy / 14)
        this.$once('hook:beforeDestroy', () => {
          clearInterval(timer)
        })
      })
    },
    onSelectQA(item, i) {
      this.closeBtn = true
      this.addnew = true
      this.$emit('setIndex', i)
    },
    addNewZY() {
      this.closeBtn = false
      this.message = ''
      this.addnew = false
    },
    addNewQA() {
      if (this.message.trim()) {
        this.visible = false
        this.$emit('addNewQA', {
          addData: {
            content: this.message
          },
          cb: this.clearAddMsg,
          user: this.value
        })
      } else {
        this.$message({
          type: 'error',
          showClose: true,
          message: '质疑不能为空',
          center: true
        });
      }
    },
    clearAddMsg() {
      this.addnew = true
      this.closeBtn = true
      this.message = ''
      this.$emit('setIndex', 0);
    },
    onCloseQuestion() {
      this.$emit('onCloseQuestion', {
        ...this.leftQAList[this.qaIndex]
      })
    },
    onSendquestion() {
      if (this.message.trim()) {
        if (this.placeHolderZY === '回复质疑：') {
          this.closeBtn = true
          this.$emit('onSendquestion', {
            item: {
              ...this.leftQAList[this.qaIndex],
              b: localStorage.getItem('user'),
              content: this.message
            },
            cb: this.onScrollBottom,
            i: this.qaIndex,
            user: this.value
          })
        } else {
          this.addNewQA()
        }
      } else {
        this.$message({
          type: "error",
          message: '请输入您要发送的内容'
        });
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.wrap-zy {
  min-height: 520px;
  max-height: 560px;
  display: flex;

  .left-wrap {
    width: 20%;
    min-height: 520px;
    max-height: 540px;
    overflow-y: scroll;
    border-right: 1px solid #ccc;

    .l-q-list-wrap {
      text-align: left;
      padding: 16px 8px;
      display: flex;
      align-items: center;
      cursor: pointer;
      box-sizing: border-box;
      border-bottom: 1px solid #ccc;
      border-right: none;
      border-top: none;

      p {
        flex: 1;
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
        padding-left: 10px;
        color: #666;
      }
    }

    .qa-active {
      border-bottom: 1px solid #F56C6C;
      box-sizing: border-box;

      p {
        font-weight: 600;
      }
    }

    .lt-zy {
      padding: 16px;
      border-bottom: 1px solid #ccc;
      cursor: pointer;

      span {
        padding-right: 20px;
        font-weight: 600;
      }
    }
  }

  .right-wrap {
    width: 80%;
    min-height: 520px;
    padding: 0 20px;
    display: flex;
    flex-direction: column;

    .submit-q-wrap {
      height: 150px;
      border-radius: 6px;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: space-between;
      margin-top: 20px;

      .bottom-send-wrap {
        padding: 10px;
        display: flex;

        p {
          padding: 8px 24px;
          box-sizing: border-box;
          cursor: pointer;
        }

        .closebtn {
          border-radius: 4px;
          margin-right: 20px;
          color: #333;
          background-color: rgb(80, 140, 184);
          color: #fff;
          padding: 8px 16px;
        }

        .sendbtn {
          border-radius: 4px;
          background-color: rgb(80, 140, 184);
          color: #fff;
        }
      }
    }

    .msg-row {
      padding: 10px;
      padding-top: 0;
      padding-bottom: 0;
      background: #7272720f;
      box-sizing: border-box;
      overflow: hidden;

      &:last-of-type {
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;

        .list-b-msg {
          border-left: none;
        }
      }

      &:first-of-type {
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;

        .list-top-msg {
          padding-top: 10px;
        }
      }

    }

    .list-wrap {
      flex: 1;
      overflow-y: scroll;

      .list-top-msg {
        display: flex;
        justify-content: space-between;
        align-items: center;

        i {
          padding: 6px;
          border-radius: 50%;
          background: #ccc;
          margin-right: 12px;
        }
      }

      .list-b-msg {
        min-height: 40px;
        display: flex;
        align-items: center;
        border-left: 1px solid #ddd;
        margin: 4px 0;
        margin-left: 12px;
        padding-left: 10px;

      }
    }
  }
}
</style>
